body {
  color: $main-color;
  font-family: $opensans;
}

@mixin underline() {
  &:after {
    content: "";
    background: url("../images/short-underline.png") center no-repeat;
    display: block;
    padding-top: 10px;
    margin-top: 20px;
    text-aling: center;
  }
}

@mixin menuBullet($color) {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    float: left;
    background: $color;
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 5px;
    margin-right: 20px;
}