// Glyphicons font path
$icon-font-path:              "../fonts/";
$opensans:                    'Open Sans', sans-serif;

// Grid settings
$main-sm-columns:             12;
$sidebar-sm-columns:          4;
$grid-float-breakpoint:       992px;

// Colors
$brand-primary:               #27ae60;
$main-color:                  #6d6e70;
$gray:                        #aeaeae;
$dark-gray:                   #4d4d4d;
$darker-gray:                 #414141;
$purple:                      #9e005d;
$yellow:                      #d9e021;
$red:                         #ff6e6f;
$rainbow-1:                   #f99f1e;
$rainbow-2:                   #f15660;
$rainbow-3:                   #e12893;
$rainbow-4:                   #b93ca0;
$rainbow-5:                   #7d5ab4;
$rainbow-6:                   #2f82ce;


// Header
$slider-rainbow-color:        white;
$menu-orarend:                $rainbow-1;
$menu-helyszin-parkolas:      $rainbow-2;
$menu-helyszin-desktop:       $rainbow-1;
$menu-bejelentkezes:          $rainbow-3;
$menu-bejelentkezes-desktop:  $rainbow-2;
$menu-oktatok:                $rainbow-4;
$menu-oktatok-desktop:        $rainbow-3;
$menu-arak:                   $rainbow-5;
$menu-arak-desktop:           $rainbow-4;
$menu-oratipusok-desktop:     $rainbow-5;
$menu-rolunk:                 $rainbow-6;

// Footer
$footer-link-color:           $main-color;
$copyright-color:             white;
$footer-border-color:         $gray;

// Home
$welcome-title-color:         $purple;
$welcome-subtitle-color:      $main-color;
$timetable-bg-color:          $dark-gray;
$timetable-title-color:       white;
$timetable-day-color:         white;
$timetable-day-separator:     $darker-gray;
$timetable-time-color:        white;
$timetable-subtitle-color:    white;
$timetable-instructor-color:  $yellow;
$location-title-color:        $purple;
$location-caption-color:      $purple;
$checkin-title-color:         white;
$checkin-header-bg:           $red;
$checkin-contact-color:       white;
$instructor-name-color:       white;
$price-category-title-color:  $purple;