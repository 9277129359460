.home {
  .banner {
    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 140px;
      top: inherit;
      background: -webkit-linear-gradient(transparent, black);
      background: -o-linear-gradient(transparent, black);
      background: -moz-linear-gradient(transparent, black);
      background: linear-gradient(transparent, black);
    }
  }
}
.banner {
  .navbar-header {
    background: transparent;
    position: absolute;
    z-index: 1000;
    right: 15px;
    left: 15px;
    border: 0;
    @media (min-width: $screen-md-min) {
      max-width: 1100px;
      margin: 0 auto;
    }
    .lang-selector {
      position: absolute;
      top: 40px;
      right: 100px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      a {
        color: #808080;
        &:hover, &:focus {
          color: #808080;
          text-decoration: none;
        }
      }
    }
    .navbar-toggle {
      border: 0;
      background: url("../images/menu-button.png");
      width: 43px;
      height: 42px;
      position: absolute;
      z-index: 1100;
      right: 20px;
      top: 20px;
    }
    .navbar-brand {
      height: 97px;
      @media (min-width: $screen-md-min) {
        margin-left: 0;
        margin-top: 15px;
      }
      #body-factory-logo {
        display: block;
        width: 60%;
        height: auto;
        #logo-big-text, #logo-small-text, #logo-dots {
          fill: white;
        }
      }
    }
  }
  &:before {
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;
    background: -webkit-linear-gradient(black, transparent);
    background: -o-linear-gradient(black, transparent);
    background: -moz-linear-gradient(black, transparent);
    background: linear-gradient(black, transparent);
  }
  .nav-primary {
    position: absolute;
    z-index: 5;
    right: 15px;
    padding-top: 75px;
    border-top: 0;
    min-width: 190px;
    background: rgba(0, 0, 0, 0.8);
    @media (min-width: $screen-md-min) {
      z-index: 1000;
      padding-top: 0;
      background: transparent;
      left: 0;
      max-width: 1200px;
      margin: 0 auto;
    }
    @media (min-width: $screen-md-min) {
      right: 30px;
    }
    .navbar-brand {
      height: 97px;
      @media (min-width: $screen-md-min) {
        margin-left: 0;
        margin-top: 15px;
      }
      #body-factory-logo {
        display: block;
        width: 60%;
        height: auto;
        #logo-big-text, #logo-small-text, #logo-dots {
          fill: white;
        }
      }
    }
    .top-header {
      @media (min-width: $screen-md-min) {
        min-width: 545px;
        right: 0;
        position: absolute;
      }
      p {
        margin-bottom: 0;
        margin-top: 20px;
        font-size: 12px;
        text-transform: uppercase;
        color: #999;
        @media (min-width: $screen-md-min) {
          margin-top: 30px;
        }
        &.lang-selector {
          color: white;
          margin-right: 25px;
          span {
            color: #999;
          }
          a {
            color: #999;
          }
        }
        &.social {
          span {
            margin-top: -10px;
            margin-left: 20px;
          }
        }
      }
    }
    .navbar-nav {
      margin-top: 10px;
      @media (min-width: $screen-md-min) {
        background: url("../images/nav-sep.png") top center no-repeat;
        background-size: 100%;
        max-width: 635px;
        margin-top: 70px;
      }
      li {
        &.menu-orarend, &.menu-schedule {
          a {
            &:before {
              @media (max-width: $screen-md-max) {
                @include menuBullet($menu-orarend);
              }
            }
          }
        }
        &.menu-helyszin, &.menu-location {
          a {
            &:before {
              @include menuBullet($menu-helyszin-parkolas);
              @media (min-width: $screen-md-min) {
                background-color: $menu-helyszin-desktop;
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        &.menu-bejelentkezes, &.menu-sign-up {
          a {
            &:before {
              @include menuBullet($menu-bejelentkezes);
              @media (min-width: $screen-md-min) {
                background-color: $menu-bejelentkezes-desktop;
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        &.menu-oktatok, &.menu-instructors {
          a {
            &:before {
              @include menuBullet($menu-oktatok);
              @media (min-width: $screen-md-min) {
                background-color: $menu-oktatok-desktop;
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        &.menu-arak, &.menu-prices {
          a {
            &:before {
              @include menuBullet($menu-arak);
              @media (min-width: $screen-md-min) {
                background-color: $menu-arak-desktop;
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        &.menu-oratipusok, &.menu-class-styles {
          a {
            &:before {
              @include menuBullet($menu-oratipusok-desktop);
              @media (min-width: $screen-md-min) {
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        &.menu-rolunk, &.menu-client-testimonials {
          a {
            &:before {
              @include menuBullet($menu-rolunk);
              @media (min-width: $screen-md-min) {
                margin-right: 10px;
                margin-left: 4px;
              }
            }
          }
        }
        a {
          background: transparent;
          text-transform: uppercase;
          color: white;
          font-size: 17px;
          @media (min-width: $screen-md-min) {
            font-size: 12px;
            padding-left: 0;
            padding-right: 4px;
          }
          &:hover, &:focus {
            background: transparent;
            text-transform: uppercase;
            color: white;
          }
        }
      }
    }
  }
  .carousel {
    .semicircle {
      background: url("../images/slider-bg.png") center center no-repeat;
      font-family: $opensans;
      font-weight: 300;
      font-size: 24px;
      color: $slider-rainbow-color;
      bottom: 0;
      height: 110px;
      margin-left: 0;
      margin-right: 0;
      left: 0;
      right: 0;
      width: 100%;
      position: absolute;
      z-index: 15;
      padding-left: 0;
      list-style: none;
      text-align: center;
      p {
        max-width: 175px;
        margin: 0 auto;
        padding-top: 40px;
        font-size: 18px;
      }
    }
    .carousel-inner {
      .item {
        &.active {
          min-height: 465px;
          img {
            position: absolute;
            left: -100%;
            right: -100%;
            top: -100%;
            bottom: -100%;
            margin: auto;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
          }
        }
      }
    }
    .carousel-control {
      background: transparent !important;
      .glyphicon-chevron-left {
        &:before {
          content: url("../images/left-arrow.png");
          background: black;
          display: block;
          padding: 3px;
          border-radius: 25px;
          width: 45px;
          height: 44px;
        }
      }
      .glyphicon-chevron-right {
        &:before {
          content: url("../images/right-arrow.png");
          background: black;
          display: block;
          padding: 3px;
          border-radius: 25px;
          width: 45px;
          height: 44px;
        }
      }
    }
  }
}