.content-info {
  .about-us {
    background: url("../images/rolunk-bg.png") center no-repeat;
    background-size: cover;
    min-height: 330px;
    h2 {
      max-width: 200px;
      font-size: 28px;
      letter-spacing: 3px;
      margin: 20px auto;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      color: white;
      @include underline();
      @media (min-width: $screen-sm-min) {
        max-width: none;
      }
    }
    #text-carousel {
      max-width: 1200px;
      margin: 0 auto;
    }
    .item {
      .carousel-content {
        color: white;
        font-size: 14px;
        font-style: italic;
        @media (min-width: $screen-md-min) {
          font-size: 16px;
        }
      }
    }
    .carousel-control {
      background: transparent !important;
      .glyphicon-chevron-left {
        &:before {
          content: url("../images/left-arrow.png");
        }
      }
      .glyphicon-chevron-right {
        &:before {
          content: url("../images/right-arrow.png");
        }
      }
    }
  }
  .logo {
    padding-top: 20px;
    padding-bottom: 20px;
    a {
      #body-factory-logo {
        display: block;
        width: 60%;
        height: auto;
        margin: 0 auto;
      }
      img {
        margin: 0 auto;
      }
    }
  }
  .contact {
    max-width: 270px;
    margin: 0 auto;
    padding: 20px 0;
    p {
      font-family: $opensans;
      font-size: 13px;
      span {
        font-weight: 700;
      }
      a {
        color: $footer-link-color;
      }
    }
  }
  .social-media {
    max-width: 290px;
    margin: 20px auto 30px;
    border-top: 2px solid $footer-border-color;
    border-bottom: 2px solid $footer-border-color;
    p {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 2;
      margin-top: 10px;
      span {
        margin-right: 20px;
        @media (min-width: $screen-sm-min) {
          margin-right: 10px;
        }
        @media (min-width: $screen-md-min) {
          margin-right: 20px;
        }
      }
    }
  }
  .copyright {
    background: url("../images/footer-bg.png");
    background-size: 100%;
    p {
      max-width: 270px;
      margin: 0 auto;
      color: $copyright-color;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 11px;
      text-transform: uppercase;
      @media (max-width: $screen-sm-min) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}