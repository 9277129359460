.page {
  .hentry {
    .entry-title {
      margin-top: 0;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 35px;
      &:after {
        content: "";
        background: url("../images/short-underline.png") center left no-repeat;
        display: block;
        padding-top: 10px;
        margin-top: 20px;
        text-aling: center;
      }
    }
    .entry-content {
      h3 {
        color: #9e005d;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 15px;
      }
    }
  }
}