.home {
  .about {
    margin-bottom: 20px;
    h1 {
      font-weight: 300;
      color: $welcome-title-color;
      text-transform: uppercase;
      text-align: center;
      font-size: 26px;
      letter-spacing: 3px;
      margin-top: 10px;
      @media (min-width: $screen-sm-min) {
        text-align: left;
      }
      &:after {
        content: "";
        background: url("../images/short-underline.png") center no-repeat;
        display: block;
        padding-top: 10px;
        margin-top: 30px;
        @media (min-width: $screen-sm-min) {
          background-position: top left;
        }
      }
      span {
        margin-top: 10px;
        display: block;
        color: $welcome-subtitle-color;
        letter-spacing: normal;
        font-size: 17px;
      }
    }
  }
  .orarend, .schedule {
    margin-left: -15px;
    margin-right: -15px;
    background: $timetable-bg-color url("../images/orarend-bg.png") center no-repeat;
    background-size: cover;
    &:before {
      content: " ";
      background: url("../images/separator.png") repeat-y;
      height: 10px;
      display: block;
      background-size: 100%;
    }
    &:after {
      content: " ";
      background: url("../images/separator-inverse.png") repeat-y;
      height: 10px;
      display: block;
      background-size: 100%;
    }
    h2 {
      color: $timetable-title-color;
      text-align: center;
      font-weight: 300;
      text-transform: uppercase;
    }
    h3 {
      text-align: center;
      color: $timetable-day-color;
      font-size: 14px;
      padding-top: 7px;
      padding-bottom: 6px;
      text-transform: uppercase;
      margin-left: -15px;
      margin-right: -15px;
      border-bottom: 1px solid $timetable-day-separator;
      box-shadow:inset 0 -15px 5px -16px #111;
      &.monday {
        background: url("../images/monday.png") center no-repeat;
      }
      &.tuesday {
        background: url("../images/tuesday.png") center no-repeat;
      }
      &.wednesday {
        background: url("../images/wednesday.png") center no-repeat;
      }
      &.thursday {
        background: url("../images/thursday.png") center no-repeat;
      }
      &.friday {
        background: url("../images/friday.png") center no-repeat;
      }
      &.saturday {
        background: url("../images/saturday.png") center no-repeat;
      }
    }
    .days {
      padding-left: 0;
      padding-right: 0;
      .time {
        color: $timetable-time-color;
        font-size: 15px;
        font-weight: 300;
        text-align: right;
        padding-right: 0;
        @media (min-width: $screen-sm-min) {
          text-align: center;
        }
      }
      .classes {
        @media (min-width: $screen-sm-min) {
          text-align: center;
        }
        padding-right: 0;

        .title {
          color: $timetable-subtitle-color;
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 0;
        }
        .instructor {
          color: $timetable-subtitle-color;
          font-size: 12px;
          font-style: italic;
          span {
            color: $timetable-instructor-color;
          }
        }
      }
    }
  }
  .helyszin-parkolas, .location-parking {
    background: url("../images/helyszin-mobil-bg.png") 45% 15% no-repeat;
    background-size: cover;
    margin-left: -15px;
    margin-right: -15px;
    background-attachment: fixed;
    @media (min-width: $screen-sm-min) {
      background: url("../images/helyszin-bg.png") 45% 80% no-repeat;
      background-size: cover;
      min-height: 500px;
    }
    h2 {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: 3px;
      color: $location-title-color;
      text-align: center;
      text-transform: uppercase;
      @include underline();
    }
    .content {
      text-align: center;
      max-width: 300px;
      margin: 0 auto;
      font-size: 12px;
      a {
        text-decoration: none !important;
      }
      figure {
        border: 0;
        text-transform: uppercase;
        color: $location-caption-color;
        padding: 0;
        background: transparent;
      }
    }
    .gallery {
      text-align: center;
      margin-bottom: 20px;
      img {
        display: inline-block;
      }
      .id2 {
        display: none;
        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }
      }
      .id3 {
        display: none;
        @media (min-width: $screen-md-min) {
          display: inline-block;
        }
      }
    }
  }
  .bejelentkezes, .sign-up-for-a-class {
    margin-left: -15px;
    margin-right: -15px;
    &:before {
      content: " ";
      background: url("../images/separator.png") repeat-y;
      height: 10px;
      display: block;
      background-size: 100%;
    }
    .content-header {
      text-align: center;
      background: $checkin-header-bg;
      padding-bottom: 60px;
      padding-left: 0;
      padding-right: 0;
      .header-block {
        @media (min-width: $screen-sm-min) {
          max-width: 420px;
          margin: 0 0 0 auto;
          padding-lefT: 15px;
          min-height: 200px;
        }
        h2 {
          font-size: 28px;
          font-weight: 300;
          letter-spacing: 3px;
          color: $checkin-title-color;
          text-transform: uppercase;
          padding-top: 20px;
          margin-top: 0;
          @include underline();
          @media (min-width: $screen-sm-min) {
            text-align: left;
            &:after {
              background-position: top left;
            }
          }
        }
        p {
          color: $checkin-contact-color;
          font-size: 15px;
          margin-bottom: 0;
          @media (min-width: $screen-sm-min) {
            text-align: left;
          }
          span {
            font-weight: 600;
          }
        }
      }

    }
    .content {
      margin-top: 20px;
      @media (min-width: $screen-sm-min) {
        max-width: 420px;
      }
      p {
        padding-left: 40px;
      }
      img {
        float: left;
      }
    }
  }
  .oktatok-arak {
    margin-left: -15px;
    margin-right: -15px;
    .oktatok-block {
      padding-top: 15px;
      padding-bottom: 230px;
      background: #ebefef;
      .hazirend {
        display: block;
        padding: 20px;
        text-align: center;
        background: #00ac98;
        color: white;
        text-transform: uppercase;
        font-weight: lighter;
        font-size: 26px;
        &:hover, &:focus {
          text-decoration: none;
        }
        &:before {
          content: '';
          background: url("../images/hazirend.png");
          width: 37px;
          height: 33px;
          display: block;
          float: left;
        }
      }
    }
    .oktatok {
      max-width: 420px;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
      @media (min-width: $screen-sm-min) {
        float: right;
      }
      h2 {
        font-size: 28px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        @include underline();
      }
      .oktato {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        margin-bottom: 5px;
        a {
          display: block;
          margin: 0 auto;
          position: relative;
          &:hover, &:focus {
            text-decoration: none;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            top: inherit;
            background: -webkit-linear-gradient(transparent,#000);
            background: linear-gradient(transparent,#000);
          }
          p {
            margin-bottom: 0;
            font-size: 11px;
            font-style: italic;
            margin-top: -30px;
            color: $instructor-name-color;
            min-height: 30px;
            max-height: 30px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 100;
          }
        }
      }
    }
    .arak, .prices {
      h2 {
        font-weight: 300;
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        @include underline();
        @media (min-width: $screen-sm-min) {
          text-align: left;
          padding-left: 40px;
          &:after {
            background-position: top left;
          }
        }
      }
      .content {
        font-size: 12px;
        max-width: 420px;
        h3 {
          color: $price-category-title-color;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          @media (min-width: $screen-sm-min) {
            padding-left: 40px;
          }
        }
        h4 {
          color: black;
          font-size: 12px;
          font-weight: 700;
          @media (min-width: $screen-sm-min) {
            padding-left: 40px;
          }
        }
        p {
          margin-bottom: 3px;
          @media (min-width: $screen-sm-min) {
            padding-left: 40px;
          }
        }
      }
    }
  }
}